import { lazy } from 'react';
import { Route } from 'react-router';

import { RISK_CONTRIBUTOR_ROUTES } from './routes';

const Customers = lazy(() => import('@corify/pages/clients/customers/customers'));
const RootLayout = lazy(() => import('@corify/layout/root-layout'));
const BrokerLeftNavigationLayout = lazy(() => import('@corify/layout/risk-contributor-layout'));
const CustomerStructure = lazy(() => import('@corify/pages/customer/structure/customer-structure'));

export const getBrokerRiskContributorRoutes = () => [
  <Route key="broker-risk-contributor-list" element={<RootLayout />}>
    <Route path={RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_CUSTOMERS_ROUTE} element={<Customers />} />
  </Route>,
  <Route key="broker-risk-contributor" element={<BrokerLeftNavigationLayout />}>
    <Route path={RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_CUSTOMERS_STRUCTURE_ROUTE} element={<CustomerStructure />} />
  </Route>,
];
