import { getAdminRoutes } from '@corify/routes/admin/get-admin-routes';
import { ADMIN_ROUTES } from '@corify/routes/admin/routes';
import { getClientsRoutes } from '@corify/routes/clients/get-clients-routes';
import { getBrokerRiskContributorRoutes } from '@corify/routes/clients/risk-contributor/get-broker-risk-contributor-routes';
import { getRiskContributorBaseRoutes } from '@corify/routes/clients/risk-contributor/get-risk-contributor-base-routes';
import { getRiskContributorCustomerAccessRoutes } from '@corify/routes/clients/risk-contributor/get-risk-contributor-customer-access-routes';
import { getRiskContributorObjectsRoutes } from '@corify/routes/clients/risk-contributor/get-risk-contributor-objects-routes';
import { getRiskSubmissionsRoutes } from '@corify/routes/clients/risk-submission/get-risk-submissions-routes';
import { getFleetManagementRoutes } from '@corify/routes/fleet-management/get-fleet-management-routes';
import { getLegalRoutes } from '@corify/routes/legal/get-legal-routes';
import { getModelMangerRoutes } from '@corify/routes/modeler/get-modeler-routes';
import { getMyTasksRoutes } from '@corify/routes/my-tasks/get-my-tasks-routes';
import { getClientCheckerRoutes } from '@corify/routes/no-client/get-client-checker-routes';
import { NO_CLIENT_ROUTES } from '@corify/routes/no-client/routes';
import { BASE_ROUTES } from '@corify/routes/routes';
import { getUsersRoutes } from '@corify/routes/users/get-users-routes';
import { riskContributionRoles } from '@corify/types/user';
import * as Sentry from '@sentry/react';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { lazy } from 'react';
import { Route, Routes } from 'react-router';

import { Loader } from '../loader/loader';
import { ConditionalRedirect } from './conditional-redirect';

const Auth0ErrorInfo = lazy(() => import('@corify/pages/auth0/auth0-error-info'));

const NotFound = lazy(() =>
  import('@corify/components/error-boundary/messages/not-found').then(m => ({ default: m.NotFound }))
);

const WithAutomaticallyClientChange = lazy(() => import('@corify/layout/automatic-client-change-layout'));

const ClientChecker = lazy(() => import('@corify/layout/security/client-checker'));
const ClientProvider = lazy(() =>
  import('@corify/context/client/client-provider').then(m => ({ default: m.ClientProvider }))
);
const ClientTypeCheckLayout = lazy(() => import('@corify/layout/security/client-type-check-layout'));
const UserTypeCheckLayout = lazy(() => import('@corify/layout/security/user-type-check-layout'));
const ProtectedLayout = lazy(() => import('@corify/layout/security/protected-layout'));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const App = () => {
  const { flagsReady, flagsError } = useFlagsStatus();
  const cromRelease = useFlag('crom-release');
  const lobConfiguration = useFlag('lob-configuration');
  const robManagement = useFlag('rob-management');

  if (!flagsReady && !flagsError) {
    return (
      <div className="h-screen w-screen">
        <Loader data-testid="flags-loading" />
      </div>
    );
  }

  return (
    <SentryRoutes>
      <Route key="auth-error" path="/auth-error" element={<Auth0ErrorInfo />} />
      {getLegalRoutes()}
      <Route key="protected" element={<ProtectedLayout />}>
        <Route path={NO_CLIENT_ROUTES.NO_CLIENT_ROUTE} element={<UserTypeCheckLayout type="WITHOUT_CLIENT" />}>
          {getClientCheckerRoutes()}
        </Route>
        <Route element={<UserTypeCheckLayout type="MODELER" />}>
          {getModelMangerRoutes(cromRelease, lobConfiguration)}
        </Route>
        <Route path={ADMIN_ROUTES.ADMIN_ROUTE} element={<UserTypeCheckLayout type="CORIFY_ADMIN" />}>
          {getAdminRoutes()}
        </Route>
        <Route
          element={
            <ClientChecker>
              <ClientProvider>
                <WithAutomaticallyClientChange />
              </ClientProvider>
            </ClientChecker>
          }
        >
          <Route
            element={<ClientTypeCheckLayout validate={['BROKER_MAIN_CLIENT_ADMIN', 'CUSTOMER_MAIN_CLIENT_ADMIN']} />}
          >
            {getClientsRoutes()}
            {getUsersRoutes()}
          </Route>
          <Route
            element={<ClientTypeCheckLayout validate={['BROKER_KEY_ACCOUNT_MANAGER', 'BROKER_INSURANCE_EXPERT']} />}
          >
            {getBrokerRiskContributorRoutes()}
          </Route>
          <Route
            element={
              <ClientTypeCheckLayout
                validate={[
                  'BROKER_KEY_ACCOUNT_MANAGER',
                  'BROKER_INSURANCE_EXPERT',
                  'CUSTOMER_RISK_EXPERT',
                  'CUSTOMER_RISK_MANAGER',
                ]}
              />
            }
          >
            {getRiskContributorBaseRoutes()}
            {getRiskContributorObjectsRoutes()}
          </Route>
          <Route element={<ClientTypeCheckLayout validate={['BROKER_KEY_ACCOUNT_MANAGER']} />}>
            {getRiskContributorCustomerAccessRoutes()}
            {robManagement && getRiskSubmissionsRoutes()}
          </Route>
          <Route element={<ClientTypeCheckLayout validate={['FLEET_MANAGER', 'CFM_ADMIN']} />}>
            {getFleetManagementRoutes()}
          </Route>
          <Route element={<ClientTypeCheckLayout validate={riskContributionRoles} />}>{getMyTasksRoutes()}</Route>
        </Route>
        <Route path="*" element={<ConditionalRedirect />}></Route>
      </Route>
      <Route key="notfound" path={BASE_ROUTES.NOT_FOUND_ROUTE} element={<NotFound />}></Route>
      {/* This is needed for not authenticated users, so they can be redirected, if they try to call some unknown url */}
      <Route key="notfound" path="*" element={<NotFound />}></Route>
    </SentryRoutes>
  );
};
