import { lazy } from 'react';
import { Route } from 'react-router';

import { RISK_CONTRIBUTOR_ROUTES } from './routes';

const CustomerAccess = lazy(() => import('@corify/pages/customer/access/customer-access'));
const AccessesDetail = lazy(() => import('@corify/pages/customer/access-details/customer-access-details'));

const BrokerLeftNavigationLayout = lazy(() => import('@corify/layout/risk-contributor-layout'));

export const getRiskContributorCustomerAccessRoutes = () => [
  <Route key="broker-risk-contributor-customer-access" element={<BrokerLeftNavigationLayout />}>
    <Route path={RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_CUSTOMER_ACCESS_ROUTE} element={<CustomerAccess />} />
    <Route path={RISK_CONTRIBUTOR_ROUTES.RISK_CONTRIBUTOR_CUSTOMER_ACCESS_DETAIL_ROUTE} element={<AccessesDetail />} />
  </Route>,
];
