import { lazy } from 'react';
import { Route } from 'react-router';

import { RISK_SUBMISSIONS_ROUTES } from './routes';

const RootLayout = lazy(() => import('@corify/layout/root-layout'));
const RiskBundles = lazy(() => import('@corify/pages/risk-submission/risk-bundles/risk-bundles'));
const RiskBundle = lazy(() => import('@corify/pages/risk-submission/risk-bundle/risk-bundle'));
const RiskBundleManageRisks = lazy(
  () => import('@corify/pages/risk-submission/risk-bundle-manage-risks/risk-object-bundle-manage-risks')
);

export const getRiskSubmissionsRoutes = () => [
  <Route key="risk-submission-route" element={<RootLayout classes={{ content: 'p-0' }} />}>
    <Route path={RISK_SUBMISSIONS_ROUTES.RISK_BUNDLES_ROUTE} element={<RiskBundles />} />
    <Route path={RISK_SUBMISSIONS_ROUTES.RISK_BUNDLE_ROUTE} element={<RiskBundle />} />
  </Route>,
  <Route key="risk-submission-management-route" element={<RootLayout classes={{ content: 'p-0 bg-white' }} />}>
    <Route path={RISK_SUBMISSIONS_ROUTES.RISK_BUNDLE_MANAGE_RISKS_ROUTE} element={<RiskBundleManageRisks />} />
  </Route>,
];
