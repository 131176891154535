// eslint-disable-next-line simple-import-sort/imports
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import './index.css';

// This needs to be imported before React to avoid a bug in react-scan
// eslint-disable-next-line import/order
import { scan } from 'react-scan';

import { App } from '@corify/components/app/app';
import { Loader } from '@corify/components/loader/loader';
import { DEFAULT_PAGE_SIZE } from '@corify/components/table/components/pagination/pagination';
import { sentryInit } from '@corify/logging/sentry-init';
import { Environment, environmentMap } from '@corify/types/environments';
import * as Sentry from '@sentry/react';
import FlagProvider from '@unleash/proxy-client-react';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router';
import { LicenseManager } from 'ag-grid-enterprise';

import { CorifyTableProvider } from './components/table/provider/corify-table-provider';
import { Config } from './types/config';

if (import.meta.env?.VITE_PERFORMANCE_MEASURE) {
  scan({
    enabled: true,
    showToolbar: true,
  });
}

// Without it, msw and all deps like faker are included to production build what means few MB more in a bundle see https://github.com/vitejs/vite/discussions/3110
const initializeMsw = async (config: Config) => {
  if (import.meta.env.VITE_NODE_ENV === 'local') {
    const { createWorker } = await import('./msw/browser');

    const worker = createWorker(config);

    return worker.start();
  }
};

const helmetContext = {};

fetch('/config.json')
  .then(res => res.json())
  .then(configObj => {
    window.corify = configObj satisfies Config;

    sentryInit(configObj.ENVIRONMENT, configObj.APP_VERSION);

    if (configObj.AG_GRID_LICENSE_KEY) {
      LicenseManager.setLicenseKey(configObj.AG_GRID_LICENSE_KEY);
    }

    initializeMsw(configObj).then(() => {
      ReactDOM.createRoot(document.getElementById('root') as HTMLElement, {
        // Callback called when React automatically recovers from errors.
        onRecoverableError: Sentry.reactErrorHandler(),
      }).render(
        <StrictMode>
          <Suspense
            fallback={
              <div className="flex h-screen w-screen items-center justify-center">
                <Loader data-testid="main-suspense-loader" />
              </div>
            }
          >
            <BrowserRouter>
              <CorifyTableProvider defaultPagination={{ pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE }}>
                <HelmetProvider context={helmetContext}>
                  <FlagProvider
                    config={{
                      disableMetrics: true,
                      url: configObj.UNLEASH_URL, // url to gitlab feature flags repository
                      clientKey: configObj.UNLEASH_INSTANCE_ID, // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
                      refreshInterval: configObj.UNLEASH_APP_REFRESH_INTERVAL, // How often (in seconds) the client should poll the proxy for updates
                      appName: environmentMap[configObj.NODE_ENV as Environment] ?? configObj.NODE_ENV,
                    }}
                  >
                    <Helmet>
                      <title>Corify marketplace</title>
                    </Helmet>
                    <App />
                  </FlagProvider>
                </HelmetProvider>
              </CorifyTableProvider>
            </BrowserRouter>
          </Suspense>
        </StrictMode>
      );
    });
  })
  .catch(() => {
    document.body.insertAdjacentHTML(
      'afterbegin',
      '<h1 style="text-align: center; margin-top: 20%">Configuration file is missing</h1>'
    );
  });

declare global {
  interface Window {
    corify: Config;
  }
}
