import { SortProps } from '@corify/services/frontend-api/types/api-helper';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';

import { Order } from '../sort-field/sort-field';

export function useTableSort<T extends string>(initialState: SortProps<T>) {
  const { pathname } = useLocation();
  const [sorting, setSort] = useState<SortProps<T>>(initialState);
  const { sort, sortDirection } = sorting;
  const [cacheSort, setCacheSort] = useLocalStorage<string | null>(`sort-${pathname}`, null);
  const [cacheSortDirection, setCacheSortDirection] = useLocalStorage<string | null>(
    `sort-direction-${pathname}`,
    null
  );

  useEffect(() => {
    if (cacheSort && sort !== cacheSort) {
      setSort(prev => ({ ...prev, sort: cacheSort as T }));
    }

    if (cacheSortDirection && sortDirection !== cacheSortDirection) {
      setSort(prev => ({ ...prev, sortDirection: cacheSortDirection as Order }));
    }
  }, [cacheSort, cacheSortDirection]);

  const handleSortChange = useCallback(
    (column: T) => {
      if (column !== sort) {
        setSort({ sortDirection: 'ASCENDING', sort: column });
        setCacheSort(column);
        setCacheSortDirection('ASCENDING');
        return;
      }

      if (sortDirection === 'ASCENDING') {
        setSort(prev => ({ ...prev, sortDirection: 'DESCENDING' }));
        setCacheSortDirection('DESCENDING');
        return;
      }

      if (sortDirection === 'DESCENDING') {
        setSort(prev => ({ ...prev, sortDirection: 'ASCENDING' }));
        setCacheSortDirection('ASCENDING');
        return;
      }
    },
    [setCacheSort, setCacheSortDirection, sort, sortDirection]
  );

  const getSortIcon = useCallback(
    (column: T) => {
      if (column !== sort) {
        return;
      }

      if (sortDirection === 'ASCENDING') {
        return 'ascending';
      }

      if (sortDirection === 'DESCENDING') {
        return 'descending';
      }
    },
    [sort, sortDirection]
  );

  return { sorting, sort, sortDirection, handleSortChange, getSortIcon };
}
